import React from 'react';
import _ from 'lodash';
import { Range as RCRange } from 'rc-slider';
import 'rc-slider/assets/index.css';
import './range.scss';

const Bar = ({ value, marks }) => {
  const maxValue = _.max(marks);
  const barHeight = 70;
  const percentageDiff = ((maxValue - value) / maxValue) * 100;
  const percentageDiffHeight = (barHeight * percentageDiff) / 100;
  let height =
    value === maxValue ? barHeight : barHeight - percentageDiffHeight;
  return <div className="bars" style={{ height: `${height}px` }}></div>;
};

const getRangeMarks = (marks, marksCounts) => {
  const rangeMarks = {};
  for (let i = 0; i < marks.length; i++) {
    rangeMarks[`${marks[i]}`] = (
      <Bar value={marksCounts[i]} marks={marksCounts} />
    );
  }
  return rangeMarks;
};

const Range = ({ value, minValue, step, update, marks, marksCounts }) => {
  const rangeMarks = getRangeMarks(marks, marksCounts);
  return (
    <div className="range-container">
      <RCRange
        min={minValue}
        max={_.max(marks)}
        value={value}
        step={step}
        marks={rangeMarks}
        onChange={v => {
          if (v[1] - v[0] >= step) {
            update(v);
          }
        }}
        allowCross={false}
      />
    </div>
  );
};

export default Range;
