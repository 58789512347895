import React from 'react';
import Button from 'components/Button';
import styles from './header.module.scss';

const Header = ({ title, departments, onClick, selectedTeamIndex }) => (
  <div className={styles.projectTeam}>
    <h3>{title}</h3>
    <ul>
      {departments &&
        departments.map((department, index) => (
          <li
            key={`department${index}`}
            className={`${index === selectedTeamIndex ? styles.active : ''}`}
          >
            <Button handleClick={() => onClick(index)}>
              {department.name}
            </Button>
          </li>
        ))}
    </ul>
  </div>
);

export default Header;
