import React, { useState } from 'react';
import _ from 'lodash';
import Footer from 'components/FilterFooter';
import LabelCard from 'components/LabelCard';
import styles from './year.module.scss';

const Year = ({ update, state, years }) => {
  const [value, setValue] = useState(state);
  return (
    <div>
      <div className={styles.auction}>
        {years.map(year => (
          <LabelCard
            label={year}
            key={`year_${year}`}
            selected={!!_.filter(value, o => o === year).length}
            onClick={() => setValue(_.xor(value, [year]))}
          />
        ))}
      </div>
      <Footer
        updateDisable={!!!value.length}
        clearOnClick={() => update([])}
        updateOnClick={() => update(value)}
      />
    </div>
  );
};

export default Year;
