import * as React from 'react';
import { graphql } from 'gatsby';
import Webpage from './WebPage';

export default ({ data, location }) => {
  const { contentfulWebPageDefault } = data;
  return (
    <Webpage
      sections={contentfulWebPageDefault.sections}
      location={location}
      title={contentfulWebPageDefault.pageTitle}
      type={contentfulWebPageDefault.type}
      metaTitle={contentfulWebPageDefault?.metaTitle}
      metaDescription={contentfulWebPageDefault?.metaDescription}
    />
  );
};

export const query = graphql`
  query WebPage($id: String) {
    contentfulWebPageDefault(contentful_id: { eq: $id }) {
      pageTitle
      type
      metaTitle
      metaDescription {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      sections {
        __typename
        ... on ContentfulWebCollectionOfParagraphsLists {
          title
          collections {
            title
            id
            paragraphs {
              headline
              id
              paragraph {
                childMarkdownRemark {
                  html
                }
              }
              style
            }
          }
        }

        ... on ContentfulWebListOfHeroSections {
          heroSections {
            title
            description {
              childMarkdownRemark {
                html
              }
            }
            image {
              description
              fluid(maxWidth: 1800, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
              title
            }
            callToAction {
              ...callToActions
            }
          }
        }

        ... on ContentfulWebHeroImage {
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            fluid(maxWidth: 1800, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
            title
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          subTitle
          callToAction {
            ...callToActions
          }
        }

        ... on ContentfulWebParagraphWithHeadline {
          id
          headline
          style
          paragraph {
            childMarkdownRemark {
              html
            }
          }
          callToAction {
            ...callToActions
          }
        }

        ... on ContentfulWebSideBySide {
          ...sideBySide
        }

        ... on ContentfulWebDynamicSections {
          sections
          title
        }

        ... on ContentfulWebListOfCards {
          header
          cards {
            ...cardData
          }
        }

        ... on ContentfulWebCard {
          ...cardData
        }

        ... on ContentfulWebListOfContacts {
          id
          title
          childContentfulWebListOfContactsContactsJsonNode {
            entries {
              description
              id
              revision
              title
            }
          }
        }

        ... on ContentfulWebListOfVenues {
          childContentfulWebListOfVenuesVenuesJsonNode {
            entries {
              id
              revision
              title
            }
          }
        }

        ... on ContentfulWebRichTextSection {
          headline
          showSideIndex
          richText: childContentfulWebRichTextSectionPageDataRichTextNode {
            json
          }
        }

        ... on ContentfulWebVehicleCatalogueOptions {
          id
          title
          defaultSortOption
          heroBanner {
            title
            description {
              childMarkdownRemark {
                html
              }
            }
            image {
              description
              fluid(maxWidth: 1800, maxHeight: 620, quality: 90) {
                ...GatsbyContentfulFluid_withWebp
              }
              title
            }
            callToAction {
              ...callToActions
            }
          }
        }
        ... on ContentfulWebTimeline {
          steps {
            headline
            style
            paragraph {
              childMarkdownRemark {
                html
              }
            }

            icon {
              file {
                url
              }
            }
          }
        }

        ... on ContentfulWebVideoMedia {
          videoUrl
        }
      }
    }

    site {
      siteMetadata {
        title
      }
    }
  }

  fragment callToActions on ContentfulWebCallToAction {
    buttonText
    style
    id
    action {
      ... on ContentfulWebRedirectAction {
        url
        openInANewTab
      }
      ... on ContentfulWebOpenFormAction {
        form
      }
      ... on ContentfulWebDownloadAction {
        file {
          file {
            url
          }
        }
      }
      ... on ContentfulWebDynamicRedirectAction {
        url
        condition
        conditionFailureText
      }
    }
  }

  fragment cardData on ContentfulWebCard {
    title
    id
    textAlign
    style
    description {
      childMarkdownRemark {
        html
      }
    }
    callToAction {
      ...callToActions
    }
    background {
      description
      fluid(maxWidth: 1000, quality: 90) {
        ...GatsbyContentfulFluid_withWebp
      }
      title
    }
    icon {
      file {
        url
        fileName
      }
    }
  }

  fragment sideBySide on ContentfulWebSideBySide {
    sideBySide: sections {
      headline
      style
      paragraph {
        childMarkdownRemark {
          html
        }
      }
      callToAction {
        ...callToActions
      }
    }
    callToActionList {
      cta {
        ...callToActions
      }
    }
  }
`;
