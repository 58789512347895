import React from 'react';
import { useFeaturedVehicles } from 'services/graphql/hooks/featuredVehicles';
import HorizontalCarousel from 'components/HorizontalCarousel';
import { combineCloudinaryWithImages } from 'utils/image';

const FeaturedVehicles = ({ section }) => {
  const { vehicles } = useFeaturedVehicles();

  return (
    <HorizontalCarousel
      items={vehicles
        ?.slice(0, 15)
        ?.map(vehicle => ({
          ...vehicle,
          cloudinaryImages: [vehicle.featuredImage],
        }))
        .map(vehicle => {
          return {
            ...vehicle,
            featuredImage: combineCloudinaryWithImages(vehicle, 650)[0] ?? null,
          };
        })}
      title={section?.title}
    />
  );
};

export default FeaturedVehicles;
