import React from 'react';
import VehicleCard from 'components/VehicleCard';
import styles from './list.module.scss';
import {
  getAuction,
  getPrice,
  getTag,
  getAlgoliaItemTitle,
} from 'shared/helpers/vehicles';
import { combineCloudinaryWithImages } from 'utils/image';
import FeatureToggleManager from 'shared/helpers/FeatureToggle/featureToggleManager';
import { AUCTION_MOBILITY_WIDGET } from 'shared/helpers/FeatureToggle/constants';

const List = ({ vehicles, filtersQuery, hitsPerPage, offset }) => {
  const featureToggleManager = new FeatureToggleManager();
  return (
    <div className={styles.vehiclesListContainer}>
      <div className={styles.vehiclesList}>
        {vehicles.map((vehicle, index) => (
          <VehicleCard
            key={`vehicleCard_${vehicle.objectID}`}
            fluidImages={combineCloudinaryWithImages(vehicle, 500)}
            tag={getTag(vehicle)}
            makeModel={getAlgoliaItemTitle(vehicle)}
            auction={getAuction(vehicle)}
            price={getPrice(vehicle, vehicle?.currency)}
            lotNumber={vehicle.lotNumber}
            filtersQuery={{
              ...filtersQuery,
              pageNumber: Math.floor((index + offset) / hitsPerPage),
            }}
            slug={vehicle.slug}
            showDotsOnHover={true}
            salesForceId={vehicle?.salesForceId}
            lotAuctionMobilityId={vehicle?.lotAuctionMobilityId}
            activeAuction={vehicle?.activeAuction}
            auctionAuctionMobilityId={vehicle?.auctionAuctionMobilityId}
            className={
              featureToggleManager.isEnabled(AUCTION_MOBILITY_WIDGET)
                ? styles.catalogVehicleCard
                : ''
            }
            onlineBiddingAvailable={vehicle.onlineBiddingAvailable}
          />
        ))}
      </div>
    </div>
  );
};

export default List;
