import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import {
  SHIPPING_OPTIONS,
  QUANTITY_OPTIONS,
  NO_AVAILABLE_PRICE_MSG,
} from 'utils/orderACatalogueConstants';
import GatsbyImage from 'components/GatsbyImage/GatsbyImage';
import './cardList.scss';

const Card = ({
  img,
  auctionName,
  auctionPath,
  price,
  auction,
  handleClick,
  minimumQuantity,
  id,
  imgBackground,
}) => {
  const [quantity, setQuantity] = useState(minimumQuantity);
  const [shipping, setShipping] = useState(false);

  const [reset, setReset] = useState(false);

  const setDropdownValue = (value, type) => {
    if (type === 'Quantity') {
      setQuantity(value);
    } else {
      setShipping(value);
    }
    setReset(false);
  };
  useEffect(() => {
    function handleContextMenu(e) {
      e.preventDefault(); // prevents the default right-click menu from appearing
    }
    // add the event listener to the component's root element
    const rootElement = document.getElementById('my-component');
    rootElement.addEventListener('contextmenu', handleContextMenu);
    // remove the event listener when the component is unmounted

    return () => {
      rootElement.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  if (typeof window !== 'undefined') {
    document.addEventListener('contextmenu', e => {
      if (e.target.tagName === 'IMG') {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }
  return (
    <div className="auction-card" id="my-component">
      <div style={{ 'pointer-events': 'none' }} on className="imageWrapper">
        <GatsbyImage image={img} alt={imgBackground} className={'img-height'} />
      </div>
      <div className="auction-details">
        <Link
          to={auctionPath ? `/auction/${auctionPath}` : undefined}
          onClick={e => e.stopPropagation()}
        >
          <div className="title">{auctionName}</div>
          <div className="price">
            {(price && `$${price}`) || NO_AVAILABLE_PRICE_MSG}
          </div>
        </Link>
      </div>
      <Dropdown
        type="Quantity"
        options={QUANTITY_OPTIONS}
        label="Quantity"
        reset={reset}
        setDropdownValue={setDropdownValue}
        id={`${id}_quantity`}
        fullSize={true}
      />
      <Dropdown
        type="Shipping"
        options={SHIPPING_OPTIONS}
        label="Shipping"
        reset={reset}
        setDropdownValue={setDropdownValue}
        id={id}
        fullSize={true}
        labelClass="scrollable"
        buttonStyle={{ maxHeight: '43px' }}
      />

      <Button
        type="app-primary-button"
        disabled={quantity && shipping ? false : true}
        handleClick={async e => {
          e.stopPropagation();
          setReset(true);
          setQuantity(1);
          await handleClick(auction, quantity, shipping);
        }}
      >
        Add to Cart
      </Button>
    </div>
  );
};
export default Card;
