import * as React from 'react';

const Icon = props => (
  <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
    <path
      fill="#0053a5"
      fillRule="evenodd"
      d="M6.524 1.748C5.968.586 5.918-.425 4.048.182c-.757.252-1.161.757-1.616 1.313C.815 3.263 1.27 7.153 3.644 10.891c2.425 3.688 5.81 5.709 8.083 4.951.707-.253 1.364-.354 1.87-.96 1.313-1.465.454-1.92-.405-2.88-.758-.858-1.364-1.767-2.677-1.01-.202.253-1.314 1.364-1.87 1.263-.808-.404-1.97-1.566-2.778-2.829C5.11 8.214 4.453 6.648 4.453 5.79c.151-.556 1.566-1.111 1.92-1.212 1.212-.91.656-1.819.15-2.83z"
    />
  </svg>
);

export default Icon;
