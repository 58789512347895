import React from 'react';
import _ from 'lodash';
import Button from 'components/Button';
import styles from './filters.module.scss';

const Filters = ({ selectedTab, onClick }) => (
  <div className={styles.catalogueFilters}>
    <Button
      type={
        selectedTab === 'upcoming' ? 'app-primary-button' : 'app-default-button'
      }
      handleClick={() => _.isFunction(onClick) && onClick('upcoming')}
    >
      Upcoming
    </Button>
    <Button
      type={
        selectedTab === 'past' ? 'app-primary-button' : 'app-default-button'
      }
      handleClick={() => _.isFunction(onClick) && onClick('past')}
    >
      Past
    </Button>
  </div>
);

export default Filters;
