import { useStaticQuery, graphql } from 'gatsby';

export const useVenues = () => {
  const query = useStaticQuery(
    graphql`
      query {
        allContentfulVenue {
          nodes {
            contentful_id
            name
            address {
              ... on ContentfulAddress {
                id
                addressCountry
                streetAddress
                addressLocality
                addressRegion
                postOfficeBoxNumber
                postalCode
                crossStreets
              }
              ... on ContentfulAddressFreeform {
                id
                addressCountry
                addressLocality
                text {
                  text
                }
              }
            }
            image {
              description
              fluid(maxWidth: 1800, maxHeight: 620, quality: 90) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    `
  );
  return {
    venues: query.allContentfulVenue.nodes,
  };
};
