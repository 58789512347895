import React from 'react';
import STYLES from 'src/utils/stylesConstants';
import styles from './vehicleSaleSteps.module.scss';
import ReadMoreLess from '../ReadMoreLess';

const VehicleSaleSteps = ({ steps }) => {
  const renderStepDesc = (content, style) => {
    switch (style) {
      case STYLES.readMoreLess:
        return (
          <div className={styles.viewingText}>
            <ReadMoreLess content={content || ''} isMarkdown={true} />
          </div>
        );
      default:
        return (
          <div
            className={styles.viewingText}
            dangerouslySetInnerHTML={{
              __html: content || '',
            }}
          ></div>
        );
    }
  };

  return (
    <div className={styles.vehicleSaleSteps}>
      <div className={styles.waysText}>
        <div>
          {steps?.length &&
            steps.map((row, index) => (
              <div className={styles.blueLine} key={`vehicleSaleStep${index}`}>
                <div className={styles.leftSection}>
                  <img
                    src={row?.icon?.file?.url}
                    alt={`${row.headline} icon`}
                  />
                  <p className={styles.steps}>Step {index + 1}</p>
                  <h3>{row.headline}</h3>
                </div>
                <div className={styles.rightSection}>
                  {renderStepDesc(
                    row?.paragraph?.childMarkdownRemark?.html,
                    row?.style
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default VehicleSaleSteps;
