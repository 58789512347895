import React from 'react';
import { Formik, Form } from 'formik';
import Range from 'components/Range';
import Footer from 'components/FilterFooter';
import NumberInput from 'components/form-elements/NumberInput';
import styles from './estimate.module.scss';
import { ESTIMATE_SCHEMA } from 'components/utils/vehiclesFormUtils';
import { getEstimatedValue, MAX_ESTIMATE_PRICE } from 'shared/helpers/vehicles';

const Estimate = ({ rangeData, state, update, cancel }) => {
  const initialValue = {
    ...state,
    max: state.max >= MAX_ESTIMATE_PRICE ? MAX_ESTIMATE_PRICE : state.max,
    maxValueExceed: state.max >= MAX_ESTIMATE_PRICE,
  };

  return (
    <Formik initialValues={initialValue} validationSchema={ESTIMATE_SCHEMA}>
      {({ setValues, values, errors }) => {
        const min = getEstimatedValue(values.min);
        const max = getEstimatedValue(values.max);

        return (
          <Form
            onChange={e => {
              if (e.target.name === 'max') {
                const max = getEstimatedValue(e.target.value);
                if (max >= MAX_ESTIMATE_PRICE) {
                  setValues({
                    maxValueExceed: true,
                    max: MAX_ESTIMATE_PRICE,
                  });
                } else {
                  setValues({
                    maxValueExceed: false,
                    max: e.target.value,
                  });
                }
              }
            }}
          >
            <div className={styles.estimate}>
              <Range
                value={[
                  min,
                  max > MAX_ESTIMATE_PRICE ? MAX_ESTIMATE_PRICE : max,
                ]}
                step={rangeData.step}
                marks={rangeData.marksPrices}
                marksCounts={rangeData.marksCounts}
                minValue={rangeData.min}
                update={v => {
                  setValues({
                    min: `$${v[0]}`,
                    max: `$${
                      v[1] >= MAX_ESTIMATE_PRICE ? MAX_ESTIMATE_PRICE : v[1]
                    }`,
                    maxValueExceed: v[1] >= MAX_ESTIMATE_PRICE,
                  });
                }}
              />
              <div className={styles.rangeBox}>
                <NumberInput
                  name="min"
                  placeholder="MIN&#42;"
                  floating={false}
                  disabled={!state.max}
                  thousandSeparator={','}
                  prefix="$"
                />
                <span>&#45;</span>
                <NumberInput
                  name="max"
                  placeholder="MAX&#42;"
                  floating={false}
                  disabled={!state.max}
                  suffix={values.maxValueExceed ? '+' : ''}
                  thousandSeparator={','}
                  prefix="$"
                />
              </div>
              <Footer
                updateDisable={!!(errors.min || errors.max)}
                updateOnClick={() => update(values)}
                clearOnClick={() => {
                  setValues(rangeData.initialData);
                  cancel();
                }}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Estimate;
