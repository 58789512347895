import * as React from 'react';

const Icon = props => (
  <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
    <path
      fill="#0053a5"
      d="M8 11.98L0 4.92V14h16V4.92l-8 7.06zm0-2.13L0 2.785V2h16v.785L8 9.85z"
    />
  </svg>
);

export default Icon;
