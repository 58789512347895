import { gql } from '@apollo/client';

export const GET_ORDER_A_CATALOGUE_DATA = gql`
  query GetOrderACatalogueData {
    profile {
      id
      firstName
      lastName
      buyerStatus
    }
  }
`;

