import React, { useState, useEffect } from 'react';
import {
  YEAR_SHIPPING_OPTIONS,
  QUANTITY_OPTIONS,
  NO_AVAILABLE_PRICE_MSG,
} from 'utils/orderACatalogueConstants';
import Dropdown from 'components/Dropdown';
import styles from './orderCatalog.module.scss';
import Button from 'components/Button';
import { amplitudeEvents, trackEvent } from 'utils/amplitude';
import { useAuth0 } from '@auth0/auth0-react';

const CatalogYearSubscription = ({
  contentfulYearSubscription,
  minimumQuantity,
  setCartData,
  cart,
  setModalCarousel,
}) => {
  const { user } = useAuth0();
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(minimumQuantity);
  const [shipping, setShipping] = useState(false);
  const [reset, setReset] = useState(false);
  const [body, setBody] = useState('false');

  const updateQuantity = updatedQuantity => {
    if (!updatedQuantity) return;
    setQuantity(updatedQuantity);
    setPrice(
      updatedQuantity * catalogueYearSubscription?.yearCatalogueSubscriptionFees
    );
  };
  const getCatalogueYearSubscription = subscriptions => {
    return subscriptions.filter(subscription => {
      return subscription.year === new Date().getUTCFullYear();
    })[0];
  };
  const catalogueYearSubscription = getCatalogueYearSubscription(
    contentfulYearSubscription
  );
  useEffect(() => {
    setPrice(catalogueYearSubscription?.yearCatalogueSubscriptionFees);
    setBody(catalogueYearSubscription?.body.body);
  }, []);

  const addToCart = (shipping, price, quantity) => {
    const shippingOption = YEAR_SHIPPING_OPTIONS.find(
      option => option.id === shipping
    );
    setCartData([
      ...cart,
      {
        name: '1-Year Catalogue Subscription',
        price: price / quantity, // get price of one item, to be consistent with other items in the cart
        shippingType: shippingOption.label,
        shippingPrice: shippingOption.price * quantity,
        quantity: quantity,
      },
    ]);
  };
  return (
    <>
      <div className={styles.subscriptionSection}>
        <div className={styles.info}>
          <div className={styles.infoHeader}>Annual Subscription</div>
          <p>{body}</p>
          <p>
            Upcoming auction catalogues will ship approximately three weeks
            prior to the auction date.
          </p>
        </div>
        <div className={styles.cartSection}>
          <>
            <h4>{(price && `$${price}`) || NO_AVAILABLE_PRICE_MSG}</h4>
            {price && (
              <>
                <div className={styles.selector}>
                  <Dropdown
                    type={quantity}
                    options={QUANTITY_OPTIONS}
                    label={'Quantity'}
                    reset={reset}
                    setDropdownValue={value => {
                      updateQuantity(value);
                    }}
                    id="YearSubscriptionQuantity"
                    setReset={setReset}
                  />
                </div>
                <div className={styles.selector}>
                  <Dropdown
                    type={shipping}
                    options={YEAR_SHIPPING_OPTIONS}
                    label={'Shipping'}
                    reset={reset}
                    setDropdownValue={value => {
                      setShipping(value);
                    }}
                    id="YearSubscription"
                    setReset={setReset}
                  />
                </div>
              </>
            )}
          </>
          {price && (
            <Button
              type="app-primary-button"
              disabled={quantity && shipping ? false : true}
              handleClick={() => {
                addToCart(shipping, price, quantity);
                setReset(true);
                setQuantity(1);
                setPrice(
                  catalogueYearSubscription?.yearCatalogueSubscriptionFees
                );
                trackEvent(amplitudeEvents.ADD_TO_CART_CATALOGUE, {
                  email_address: user?.email,
                });
                setModalCarousel(true);
              }}
            >
              Add to Cart
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
export default CatalogYearSubscription;
