import { useStaticQuery, graphql } from 'gatsby';

export const useFeaturedVehicles = () => {
  const query = useStaticQuery(
    graphql`
      query {
        graphqlaws {
          featuredVehicles {
            featured
            objectID
            auctionType
            auctionName
            featuredImage {
              public_id
              width
              height
              context {
                custom {
                  alt
                }
              }
            }
            model
            make
            modelYear
            slug
            itemType
            title
            auctionSlug
            auctionEndDate
            upcomingAuctionEndDate
            auctionStartDate
          }
        }
      }
    `
  );
  return {
    vehicles: query.graphqlaws.featuredVehicles || [],
  };
};
