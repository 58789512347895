import React from 'react';
import PropTypes from 'prop-types';
import CTA from 'components/CTA';
import styles from './paragraphWithHeader.module.scss';

const ParagraphWithHeader = ({
  title,
  description,
  isMarkdown,
  callToActions,
}) => (
  <div className={styles.paragraphWithHeader}>
    <h2>{title}</h2>
    {!isMarkdown && <p>{description}</p>}
    {isMarkdown && (
      <div dangerouslySetInnerHTML={{ __html: description }}></div>
    )}
    {callToActions?.length && (
      <div className="">
        <CTA
          callToActions={callToActions}
          extraStyle={`${styles.appButton} mx-auto`}
        />
      </div>
    )}
  </div>
);

ParagraphWithHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default ParagraphWithHeader;
