import { useStaticQuery, graphql } from 'gatsby';

export const useContactPoint = () => {
  const query = useStaticQuery(
    graphql`
      query {
        allContentfulContactPoint {
          edges {
            node {
              contentful_id
              department
              email
              name
              position
              telephone
              faxNumber
              image {
                description
                fluid(maxWidth: 240, quality: 90) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  );
  return {
    contactPoint: query.allContentfulContactPoint?.edges?.map(
      edge => edge.node
    ),
  };
};
