import { useStaticQuery, graphql } from 'gatsby';

const useStaticQueryGetPrivateSales = () => {
  const query = useStaticQuery(
    graphql`
      query getPrivateSalesList {
        PrivateSales: allContentfulPrivateSaleListing(
          filter: { hideListing: { ne: true } }
        ) {
          nodes {
            salePrice
            askingPrice
            slug
            currency
            askingPriceOverride
            privateSalesPrice
            hideListing
            item {
              ...Automobilia
              ...Vehicles
            }
          }
        }
      }

      fragment Automobilia on ContentfulAutomobilia {
        lot {
          hasReservePrice
        }
        id
        title
        salesForceId

        cloudinaryImagesCombined {
          public_id
          height
          width
          context {
            custom {
              alt
            }
          }
        }
      }
      fragment Vehicles on ContentfulVehicle {
        id
        make {
          name
        }
        modelYear
        model
        salesForceId
        cloudinaryImagesCombined {
          public_id
          height
          width
          context {
            custom {
              alt
            }
          }
        }
      }
    `
  );
  return {
    privateSalesList:
      query.PrivateSales.nodes?.length &&
      query.PrivateSales.nodes
        .filter(({ item }) => item)
        .map(item => ({ ...item, ...item.item, auctionType: 'Private Sale' })),
  };
};

export default useStaticQueryGetPrivateSales;
