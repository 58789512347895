import React from 'react';
import { Formik, Form } from 'formik';
import TextInput from 'components/form-elements/TextInput';
import Footer from 'components/FilterFooter';
import styles from './year.module.scss';
import { YEAR_SCHEMA } from 'components/utils/vehiclesFormUtils';

const Year = ({ initialValues, update, cancel }) => (
  <Formik initialValues={initialValues} validationSchema={YEAR_SCHEMA}>
    {({ errors, values, setValues }) => (
      <Form>
        <div className={styles.yearHeaderFilter}>
          <div className={styles.fromTo}>
            <div className={styles.col}>
              <h3>From</h3>
              <TextInput
                name="start"
                placeholder="From&#42;"
                floating={false}
                showErrorMessages={false}
                disabled={!initialValues.start}
              />
            </div>
            <div className={styles.separator}>-</div>
            <div className={styles.col}>
              <h3>To</h3>
              <TextInput
                name="end"
                placeholder="To&#42;"
                floating={false}
                showErrorMessages={false}
                disabled={!initialValues.end}
              />
            </div>
          </div>
          <Footer
            updateDisable={
              !!(errors.start || errors.end || !values.start || !values.end)
            }
            updateOnClick={() => update(values)}
            clearOnClick={() => {
              setValues(initialValues);
              cancel();
            }}
          />
        </div>
      </Form>
    )}
  </Formik>
);

export default Year;
