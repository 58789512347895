import React from 'react';
import Link from 'components/Link/Link';
import { useActiveJobs } from 'services/graphql/hooks/ActiveJobs';
import styles from './ActiveJobs.module.scss';

const ActiveJobs = () => {
  const jobs = useActiveJobs();
  return (
    <div className={styles.maxWidthContainer}>
      <div className={styles.openings}>
        {jobs.length > 0 &&
          jobs.map(({ jobTitle, jobCategory, jobPostingLink }, index) => (
            <Link to={jobPostingLink} target="_blank" key={`opening${index}`}>
              <div className={styles.openingBox}>
                <h3>{jobTitle}</h3>
                <p>{jobCategory}</p>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default ActiveJobs;
