import React, { useState, useEffect } from 'react';
import Card from './Card';
import Filters from 'features/catalogue/filters';
import './cardList.scss';
import styles from './auctions.module.scss';

const AuctionsCardList = ({
  title,
  paddingTop,
  upcoming = [],
  past = [],
  handleClick,
  minimumQuantity,
}) => {
  let className = 'text-section-web';
  useEffect(() => {
    function handleContextMenu(e) {
      e.preventDefault(); // prevents the default right-click menu from appearing
    }
    // add the event listener to the component's root element
    const rootElement = document.getElementById('my-component');
    rootElement.addEventListener('contextmenu', handleContextMenu);
    // remove the event listener when the component is unmounted

    return () => {
      rootElement.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  if (typeof window !== 'undefined') {
    document.addEventListener('contextmenu', e => {
      if (e.target.tagName === 'IMG') {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }
  const [selectedTab, setTab] = useState('upcoming');
  const [data, setData] = useState(
    selectedTab === 'upcoming' ? upcoming : past
  );

  useEffect(() => {
    setData(selectedTab === 'upcoming' ? upcoming : past);
  }, [upcoming, past]);

  return (
    <div
      className="auctions-catalogs-list"
      id="my-component"
      style={{ paddingTop }}
    >
      <div className={styles.individualCatalogues}>
        <h2>{title}</h2>
        <Filters
          selectedTab={selectedTab}
          onClick={value => {
            setTab(value);
            setData(value === 'upcoming' ? upcoming : past);
          }}
        />
      </div>
      <div className={`cards-grid ${className}`}>
        {data.map((auction, index) => (
          <Card
            id={`${index}_${selectedTab}`}
            auction={auction}
            key={`${index}_${selectedTab}`}
            img={auction.backgroundImage}
            auctionName={auction.name}
            price={auction.price || ''}
            auctionPath={auction.slug}
            handleClick={handleClick}
            minimumQuantity={minimumQuantity}
            imgBackground={auction.backgroundImageDescription}
          />
        ))}
      </div>
    </div>
  );
};

AuctionsCardList.defaultProps = {
  title: 'AUCTIONS',
  fullWidth: false,
  vertical: true,
  paddingTop: '58px',
};

export default AuctionsCardList;
