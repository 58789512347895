import * as Yup from 'yup';
import { getEstimatedValue } from 'shared/helpers/vehicles';

const validateYearRange = year => year >= 1800 && year < 2100;

const SORTING_OPTIONS = [
  {
    label: 'Newly Added',
    id: 'DEFAULT',
  },
  {
    label: 'Year (Ascending)',
    id: 'YEAR_ASC',
  },
  {
    label: 'Year (Descending)',
    id: 'YEAR_DESC',
  },
  {
    label: 'Make (A to Z)',
    id: 'MAKE_AZ',
  },
  {
    label: 'Make (Z to A)',
    id: 'MAKE_ZA',
  },
  {
    label: 'Lot Order',
    id: 'ENDING_SOONEST',
  },
  {
    label: 'Estimate (Low to High)',
    id: 'ESTIMATE_LH',
  },
  {
    label: 'Estimate (High to Low)',
    id: 'ESTIMATE_HL',
  },
];

const YEAR_SCHEMA = Yup.object().shape({
  start: Yup.number()
    .transform(value => (isNaN(value) ? undefined : value))
    .test('test', true, function (value) {
      const { end } = this.parent;
      return validateYearRange(value) && end && end > value;
    }),
  end: Yup.number()
    .transform(value => (isNaN(value) ? undefined : value))
    .test('validate', true, value => validateYearRange(value)),
});

const ESTIMATE_SCHEMA = Yup.object().shape({
  min: Yup.string().test('test', true, function (value) {
    const { max } = this.parent;
    const minValueSplit = getEstimatedValue(value);
    const maxValueSplit = getEstimatedValue(max);
    return maxValueSplit > minValueSplit;
  }),

  max: Yup.string().test('test', true, function (value) {
    const { min } = this.parent;
    const minValueSplit = getEstimatedValue(min);
    const valueSplit = getEstimatedValue(value);
    return valueSplit > minValueSplit && valueSplit - minValueSplit >= 1000;
  }),
});

export { YEAR_SCHEMA, SORTING_OPTIONS, ESTIMATE_SCHEMA };
