import { buyerStatusTypes } from 'privatePages/OnlineRegistration/register-to-bid.utils';
export const partitionAuctions = (auctions, buyerStatus) => {
  let partitionedAuctions = {
    pastAuctions: [],
    newAuctions: [],
  };
  const filteredAuctions = filterAuctions(auctions, buyerStatus);

  filteredAuctions.forEach(auction => {
    const pasredAuction = {
      backgroundImage: auction.webpage__auction?.[0]?.featureImage?.fluid,
      name: auction?.title,
      date: new Date(auction.auctionDates.startDate).toString(),
      time: new Date(auction.auctionDates.startDate).toString(),
      price: auction.offer.price,
      contentful_id: auction.contentful_id,
      slug: auction.webpage__auction?.[0]?.slug,
      backgroundImageDescription:
        auction.webpage__auction?.[0]?.featureImage?.description,
    };

    if (new Date(auction.auctionDates.endDate) <= new Date()) {
      partitionedAuctions.pastAuctions.push(pasredAuction);
    } else {
      partitionedAuctions.newAuctions.push(pasredAuction);
    }
  });
  return partitionedAuctions;
};

const filterAuctions = (auctions, buyerStatus) => {
  const filteredAuctions = [];

  auctions.forEach(auction => {
    if (!auction.offers) return;
    const offer =
      auction.offers.find(
        offer =>
          offer.category === 'Catalogue' &&
          offer.eligibleCustomerType?.some(type => type.title === buyerStatus)
      ) ??
      auction.offers.find(
        offer =>
          offer.category === 'Catalogue' &&
          offer?.eligibleCustomerType?.some(
            type => type.title === buyerStatusTypes.STANDARD
          )
      );
    const auctionDates = getDates(auction);

    if (offer && auctionDates.startDate)
      filteredAuctions.push({
        ...auction,
        offer: offer,
        auctionDates,
      });
  });

  return filteredAuctions;
};

const getDates = auction => {
  const filteredSubEvents = auction?.subEvents
    ?.filter(event => {
      return event['__typename'] === 'ContentfulSubEventAuction';
    })
    .flat();

  const filteredArray = filteredSubEvents
    .map(event => [event.startDate, event.endDate])
    .flat()
    .filter(subEvent => subEvent);
  filteredArray.sort((a, b) => new Date(a) - new Date(b));

  return {
    startDate: filteredArray.length ? filteredArray[0] : undefined,
    endDate: filteredArray.pop(),
  };
};
