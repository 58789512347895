import * as React from 'react';

const Icon = props => {
  const { color = '#FFF' } = props;
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" {...props}>
      <defs>
        <path id="prefix__plusIcon" d="M6 0v5h5v1H6v5H5V6H0V5h5V0h1z" />
      </defs>
      <use fill={color} fillRule="nonzero" xlinkHref="#prefix__plusIcon" />
    </svg>
  );
};

export default Icon;
