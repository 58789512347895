import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import LabelCard from 'components/LabelCard';
import styles from '../make/make.module.scss';

const Sort = ({ selected, update, sortOptions }) => (
  <div className={styles.labelCardSection}>
    {sortOptions.map((option, index) => (
      <LabelCard
        key={`${option.label}${index}`}
        selected={selected.id === option.id}
        label={option.label}
        onClick={() => _.isFunction(update) && update(option)}
      />
    ))}
  </div>
);

Sort.propTypes = {
  update: PropTypes.func,
  selected: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.string,
  }),
};

export default Sort;
