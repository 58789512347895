import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import LabelCard from 'components/LabelCard';
import Popover from 'components/Popover';
import styles from './dropdown.module.scss';

const Dropdown = ({
  options,
  label,
  id,
  type,
  setDropdownValue,
  reset,
  fullSize,
  setReset,
  labelClass,
  buttonStyle,
}) => {
  const [isPopoverOpen, setIspopoverOpen] = useState(false);
  const toggle = () => setIspopoverOpen(!isPopoverOpen);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setDropdownValue(selected, type);
  }, [selected]);

  useEffect(() => {
    if (reset === true) {
      setSelected(false);
      if (setReset) setReset(false);
    }
  }, [reset]);

  return (
    <Popover
      showCaretDown={true}
      label={options[selected - 1]?.label || label}
      id={`${label}_${id ? id : ''}`}
      popoverOpen={isPopoverOpen}
      toggle={toggle}
      className={`app-default-button-bg-white`}
      classNameWrapper={styles.customPopover}
      fullSize={fullSize}
      labelClass={labelClass}
      buttonStyle={buttonStyle}
    >
      <div className={styles.labelCardSection}>
        {options.map((option, index) => (
          <LabelCard
            key={`${option.label}${index}`}
            selected={selected === option.id}
            label={option.label}
            onClick={() => {
              setSelected(option.id);
              toggle();
            }}
          />
        ))}
      </div>
    </Popover>
  );
};

Dropdown.propTypes = {
  update: PropTypes.func,
  selected: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.number,
  }),
};

export default Dropdown;
