import React from 'react';
import TabsVehiclesList from 'features/TabsVehiclesList';
import useGetAvailableLots from 'services/graphql/queries/AvailableLots';

const AvailableLots = () => {
  const availableLots = useGetAvailableLots();
  return (
    <TabsVehiclesList
      tabs={[
        {
          tabName: 'Available Lots',
          tabList: availableLots,
        },
      ]}
      type={`availableLots`}
    />
  );
};

export default AvailableLots;
