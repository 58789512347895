import React, { useState, useEffect } from 'react';
import moment from 'moment';
import useStaticQueryGetAllAuctions from 'services/graphql/queries/auctions/allAuctions';
import Header from './header';
import { pastAuction } from 'shared/helpers/auctions';
import { navigate } from '@reach/router';
import './AuctionResults.scss';
import {
  getAuctionEventDates,
  getEventDateAverage,
} from 'shared/helpers/auctions';
import { isOnlineAuction } from 'utils/auctionTypes';
import AuctionsCardList from 'components/AuctionsCardList';
import { getCataloguePreFilteredByAuction } from 'shared/helpers/auctions';
import { AUCTION_PATH } from 'src/utils/pathsConstants';
import { useWebPagesSlugs } from 'services/graphql/hooks/WebPagesSlugs';
import { SLUGS } from 'utils/webPageTypesConstants';

const AuctionResults = () => {
  const { allAuctions } = useStaticQueryGetAllAuctions();
  const [filteredAuctions, setFilteredAuctions] = useState([]);
  const [auctions, setAuctions] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    years: [],
    auctionType: [],
  });
  const slugs = useWebPagesSlugs();
  // initialize auctions
  useEffect(() => {
    const pastAuctions = allAuctions
      .filter(webAuction => {
        return (
          pastAuction(webAuction.auction) &&
          webAuction?.auction?.eventStatus !== 'EventCancelled'
        );
      })
      .sort(
        (a, b) =>
          new Date(
            b.auction.subEvents.find(
              subEvent => subEvent.__typename === 'ContentfulSubEventAuction'
            )?.startDate
          ) -
          new Date(
            a.auction.subEvents.find(
              subEvent => subEvent.__typename === 'ContentfulSubEventAuction'
            )?.startDate
          )
      )
      .map(webAuction => {
        const auctionEvents = getAuctionEventDates(webAuction.auction);
        const auctionYear = moment(auctionEvents[0].startDate).format('YYYY');
        return {
          name: webAuction.auction.name,
          date: getEventDateAverage(auctionEvents),
          buttons: [
            {
              text: 'View Online Catalogue',
              slug: getCataloguePreFilteredByAuction(
                webAuction.auction,
                auctionYear,
                slugs[SLUGS.vehiclesCatalog]
              ),
            },
            {
              text: 'View Prices Realized',
              slug: AUCTION_PATH(`realized/${webAuction.slug}`),
            },
          ],
          slug: AUCTION_PATH(webAuction.slug),
          backgroundImage: webAuction.featureImage.fluid,
          auctionYear: auctionYear,
          auctionType: isOnlineAuction(webAuction.auction.eventAttendanceMode)
            ? 'Online Only'
            : 'Live Auction',
        };
      });
    setAuctions(pastAuctions);
    setFilteredAuctions(pastAuctions);
  }, [allAuctions]);

  // update the auctions on filter change
  useEffect(() => {
    setFilteredAuctions(
      auctions.filter(auction => {
        const { years, auctionType } = selectedFilters;
        let valid = true;
        if (years.length > 0)
          valid = valid && years.includes(auction.auctionYear);
        if (auctionType.length > 0)
          valid = valid && auctionType.includes(auction.auctionType);

        return valid;
      })
    );
  }, [auctions, selectedFilters]);

  const years = new Set(auctions.map(auction => auction.auctionYear));

  return (
    <>
      <Header
        years={[...years].sort()}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
      <AuctionsCardList
        fullWidth={true}
        paddingTop="0px"
        handleClick={path => navigate(path)}
        auctions={filteredAuctions}
        title=""
      />
    </>
  );
};

export default AuctionResults;
