import { useStaticQuery, graphql } from 'gatsby';

export const useActiveJobs = () => {
  const query = useStaticQuery(
    graphql`
      query {
        allContentfulCareerJob {
          edges {
            node {
              jobCategory
              jobPostingLink
              jobTitle
            }
          }
        }
      }
    `
  );
  return query.allContentfulCareerJob?.edges?.length > 0
    ? query.allContentfulCareerJob?.edges
        .map(({ node }) => node)
        .sort((a, b) => {
          const charOne = a.jobTitle.toLowerCase();
          const charTwo = b.jobTitle.toLowerCase();
          return charOne !== charTwo ? (charOne < charTwo ? -1 : 1) : 0;
        })
    : [];
};
