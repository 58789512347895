import React from 'react';
import { useVenues } from 'shared/queries/venues';
import UpcomingAuction from 'components/UpcomingAuctions';

const ListOfVenues = ({ venuesEntries }) => {
  const { venues } = useVenues();
  const filteredVenues = venuesEntries
    .filter(venue => venue.revision !== 0)
    .map(x => venues.find(venue => venue.contentful_id === x.id))
    .filter(venue => venue?.image !== null);

  return (
    <UpcomingAuction
      fullWidth={filteredVenues.length % 2 !== 0 ? true : false}
      auctions={filteredVenues?.map(venue => {
        return {
          img: venue?.image?.fluid,
          location: venue?.name,
          info: venue?.address?.streetAddress ? (
            <>
              <span className="d-block">Auction Location</span>
              <span>{venue?.address?.streetAddress}</span>
              <br />
              <span>
                {venue?.address?.addressLocality},&nbsp;
                {venue?.address?.addressRegion} &nbsp;
                {venue?.address?.postalCode}
              </span>
            </>
          ) : (
            <>
              <span className="d-block">Auction Location</span>
              <span>
                {venue?.address?.text?.text?.replace(/undefined/g, '')}
              </span>
              <br />
              <span>
                {venue?.address?.addressLocality?.replace(/undefined/g, '')}
              </span>
            </>
          ),
          subInfo: venue?.address?.crossStreets || '',
        };
      })}
      auctionDate={new Date()}
    />
  );
};

export default ListOfVenues;
