import { useStaticQuery, graphql } from 'gatsby';
import { getAuctionYear } from 'shared/helpers/vehicles';
import { pastAuction } from 'shared/helpers/auctions';
import _ from 'lodash';

const useGetAvailableLots = () => {
  const query = useStaticQuery(
    graphql`
      query getAvailableLots {
        withAskingPrice: allContentfulLot(
          filter: { askingPrice: { ne: null }, salePrice: { eq: null } }
        ) {
          edges {
            node {
              askingPrice
              slug
              contentful_id
              askingPriceOverride
              auction {
                name
                currency
                subEvents {
                  __typename
                  ... on ContentfulSubEventAuction {
                    startDate
                    endDate
                  }
                }
                webpage__auction {
                  slug
                }
              }
              item {
                ... on ContentfulAutomobilia {
                  id
                  title
                  salesForceId

                  cloudinaryImagesCombined {
                    public_id
                    height
                    width
                    context {
                      custom {
                        alt
                      }
                    }
                  }
                }
                ... on ContentfulVehicle {
                  id
                  make {
                    name
                  }
                  modelYear
                  model
                  salesForceId
                  cloudinaryImagesCombined {
                    public_id
                    height
                    width
                    context {
                      custom {
                        alt
                      }
                    }
                  }
                  cloudinaryImages1 {
                    public_id
                    height
                    width
                    context {
                      custom {
                        alt
                      }
                    }
                  }
                  cloudinaryImages2 {
                    public_id
                    height
                    width
                    context {
                      custom {
                        alt
                      }
                    }
                  }
                  cloudinaryImages4 {
                    public_id
                    height
                    width
                    context {
                      custom {
                        alt
                      }
                    }
                  }
                  cloudinaryImages5 {
                    public_id
                    height
                    width
                    context {
                      custom {
                        alt
                      }
                    }
                  }
                  cloudinaryImages6 {
                    public_id
                    height
                    width
                    context {
                      custom {
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
        }
        withOverrideAskingPrice: allContentfulLot(
          filter: { askingPriceOverride: { eq: true }, salePrice: { eq: null } }
        ) {
          edges {
            node {
              askingPrice
              slug
              contentful_id
              askingPriceOverride
              auction {
                name
                currency
                subEvents {
                  __typename
                  ... on ContentfulSubEventAuction {
                    startDate
                    endDate
                  }
                }
              }
              item {
                ... on ContentfulAutomobilia {
                  id
                  title
                  salesForceId

                  cloudinaryImagesCombined {
                    public_id
                    height
                    width
                    context {
                      custom {
                        alt
                      }
                    }
                  }
                }
                ... on ContentfulVehicle {
                  id
                  make {
                    name
                  }
                  modelYear
                  model
                  salesForceId
                  cloudinaryImagesCombined {
                    public_id
                    height
                    width
                    context {
                      custom {
                        alt
                      }
                    }
                  }
                  cloudinaryImages1 {
                    public_id
                    height
                    width
                    context {
                      custom {
                        alt
                      }
                    }
                  }
                  cloudinaryImages2 {
                    public_id
                    height
                    width
                    context {
                      custom {
                        alt
                      }
                    }
                  }
                  cloudinaryImages4 {
                    public_id
                    height
                    width
                    context {
                      custom {
                        alt
                      }
                    }
                  }
                  cloudinaryImages5 {
                    public_id
                    height
                    width
                    context {
                      custom {
                        alt
                      }
                    }
                  }
                  cloudinaryImages6 {
                    public_id
                    height
                    width
                    context {
                      custom {
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return _.uniqBy(
    [
      ...query.withOverrideAskingPrice?.edges?.map(({ node }) => ({
        ...node,
      })),
      ...query.withAskingPrice?.edges?.map(({ node }) => ({
        ...node,
      })),
    ],
    'contentful_id'
  )
    .filter(item => pastAuction(item.auction))
    .map(node => ({
      ...node,
      ...node.item,
      auctionYear: getAuctionYear(node.auction.subEvents),
      auctionName: node.auction.name,
    }));
};

export default useGetAvailableLots;
