import React, { useState } from 'react';
import { Collapse, CardBody, Card as RCard } from 'reactstrap';
import Button from 'components/Button';
import CloseIcon from 'svg/CloseIcon';
import PlusIcon from 'svg/PlusIcon';
import styles from './card.module.scss';

const Card = ({ description, title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <div className={styles.faqCard}>
      <Button handleClick={toggle} type={isOpen ? styles.blueBackground : ''}>
        <div>
          {isOpen ? (
            <CloseIcon color="#1a51a4" width={11} height={11} />
          ) : (
            <PlusIcon color="#1a51a4" />
          )}
        </div>
        {title}
      </Button>
      <Collapse className={styles.collapse} isOpen={isOpen}>
        <RCard className={styles.cardWrapper}>
          <CardBody
            className={styles.cardBody}
            dangerouslySetInnerHTML={{ __html: description }}
          ></CardBody>
        </RCard>
      </Collapse>
    </div>
  );
};

export default Card;
