import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Header from './header';
import styles from './teams.module.scss';
import GatsbyImage from 'components/GatsbyImage/GatsbyImage';
import PhoneNumber from 'components/PhoneNumber';
import { useContactPoint } from 'shared/queries/contactPoint';
import { useLocation, useParams } from '@reach/router';

const Teams = ({ title, contacts }) => {
  const { contactPoint } = useContactPoint();
  const filteredContactsPoint = contacts
    .filter(contact => contact.revision !== 0)
    .map(x => contactPoint.find(contact => contact.contentful_id === x.id));
  const formatData = filteredContactsPoint => {
    let filteredContacts = filteredContactsPoint.filter(contact => {
      return contact.department && contact.position;
    });
    let groupedContacts = _.groupBy(filteredContacts, 'department');
    const keys = Object.keys(groupedContacts);
    let formattedContacts = [];
    keys.forEach(key => {
      formattedContacts.push({ name: key, team: groupedContacts[key] });
    });
    return formattedContacts;
  };
  const isBrowser = () => typeof window !== 'undefined';
  const { search } = isBrowser() && window.location;
  var defaultIndex = 0;

  const [selectedTeamIndex, setSelectedTeamIndex] = useState(0);
  useEffect(() => {
    if (search) {
      defaultIndex = formatData(filteredContactsPoint).findIndex(data => {
        return (
          data.name.replace(/\s/g, '') === decodeURI(search).replace().slice(1)
        );
      });
    }
    setSelectedTeamIndex(defaultIndex);
  }, []);
  const selectedTeam = formatData(filteredContactsPoint)[
    selectedTeamIndex >= 0 ? selectedTeamIndex : 0
  ]?.team;
  useEffect(() => {
    if (!search.slice(1).includes('form')) {
      window.history.replaceState(
        null,
        null,
        `/contact-us?${selectedTeam[0]?.department}`
      );
    }
  }, [selectedTeamIndex]);
  return (
    <div className={styles.teamWrapper}>
      <Header
        title={title}
        departments={formatData(filteredContactsPoint)}
        selectedTeamIndex={selectedTeamIndex >= 0 ? selectedTeamIndex : 0}
        onClick={index => setSelectedTeamIndex(index)}
      />
      <div className={styles.teamMembers}>
        {selectedTeam &&
          selectedTeam
            .filter(team => {
              return team.department && team.position;
            })
            .map((team, index) => {
              return (
                <div key={`team${index}`}>
                  <GatsbyImage
                    title={team.title}
                    image={team?.image?.fluid}
                    alt={team.image?.description}
                  />
                  <p className={styles.name}>{team.name}</p>
                  <p>{team.position}</p>
                  <p>
                    <PhoneNumber number={team.telephone}></PhoneNumber>
                  </p>
                  <p>{team.email}</p>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default Teams;
