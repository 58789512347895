import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import styles from './filterFooter.module.scss';

const FilterFooter = ({ clearOnClick, updateOnClick, updateDisable }) => (
  <div className={styles.footerBtn}>
    <Button
      type={`app-transparent-no-border-button ${styles.secondaryBtn}`}
      disabled={updateDisable}
      handleClick={() => _.isFunction(clearOnClick) && clearOnClick()}
    >
      Clear
    </Button>
    <Button
      type={`app-default-button ${styles.secondaryBtn}`}
      disabled={updateDisable}
      handleClick={() => _.isFunction(updateOnClick) && updateOnClick()}
    >
      Apply
    </Button>
  </div>
);

FilterFooter.propTypes = {
  clearOnClick: PropTypes.func,
  updateOnClick: PropTypes.func,
  updateDisable: PropTypes.bool,
};

export default FilterFooter;
