import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import LabelCard from 'components/LabelCard';
import { enabled } from 'shared/helpers/vehicles';
import Footer from 'components/FilterFooter';
import { MORE_INITIAL_STATE } from './data';
import { AUCTIONS_TYPES_FILTERS } from 'utils/auctionTypes';
import styles from './more.module.scss';

const isSelected = (state, key, option) =>
  !!_.filter(state[key], o => o === option).length;

const handleClick = (state, key, option, update, isSingleSelect = false) => {
  const values = !isSingleSelect ? _.xor(state[key], [option]) : [option];
  const data = { ...state, [key]: values };
  update(data);
};
const More = ({
  state,
  update,
  auctionTypeList,
  auctionYearList,
  hasReservePrice,
  onlineBiddingAvailable,
  onlineAuctionName,
  liveAuctionVenue,
  cancel,
}) => {
  const [value, setValue] = useState(state);
  const [isDisabled, setIsDisabled] = useState(false);
  const [activeAuctionType, setActiveAuctionType] = useState('event');

  useEffect(() => {
    setIsDisabled(false);
    switch (value?.auctionType?.[0]) {
      case AUCTIONS_TYPES_FILTERS.liveAuction.value:
        setValue({
          ...value,
          onlineAuctionName: [],
        });
        return setActiveAuctionType('venue');
      case AUCTIONS_TYPES_FILTERS.onlineOnly.value:
        setValue({
          ...value,
          liveAuctionVenue: [],
        });
        return setActiveAuctionType('event');
      case AUCTIONS_TYPES_FILTERS.privateSale.value:
        setValue({
          ...value,
          onlineAuctionName: [],
          liveAuctionVenue: [],
        });
        return setIsDisabled(true);
      default:
        return setIsDisabled(false);
    }
  }, [value?.auctionType]);

  const renderVenues = () => (
    <div>
      <h5>Venue</h5>
      {liveAuctionVenue.map((option, index) => (
        <LabelCard
          key={`${option}${index}`}
          label={option}
          selected={isSelected(value, 'liveAuctionVenue', option)}
          disabled={isDisabled}
          onClick={() => {
            handleClick(value, 'liveAuctionVenue', option, setValue);
          }}
        />
      ))}
    </div>
  );

  const renderEvents = () => (
    <div>
      <h5>Event</h5>
      {onlineAuctionName.map((option, index) => (
        <LabelCard
          key={`${option}${index}`}
          label={option.split('_').slice(1).join('')}
          selected={isSelected(value, 'onlineAuctionName', option)}
          containerStyle={styles.eventsLabel}
          disabled={isDisabled}
          onClick={() =>
            handleClick(value, 'onlineAuctionName', option, setValue)
          }
        />
      ))}
    </div>
  );

  const renderAuctionsFilter = type => {
    switch (type) {
      case AUCTIONS_TYPES_FILTERS.liveAuction.value:
        return renderVenues();
      case AUCTIONS_TYPES_FILTERS.onlineOnly.value:
        return renderEvents();
      case AUCTIONS_TYPES_FILTERS.privateSale.value:
        return activeAuctionType === 'venue' ? renderVenues() : renderEvents();
      default:
        return renderEvents();
    }
  };

  return (
    <div>
      <div className={styles.labelCardSection}>
        <div className={styles.innerCard}>
          <div className={styles.innerCardOne}>
            <div>
              <h5>Auction Type</h5>
              {auctionTypeList.map((option, index) => (
                <LabelCard
                  key={`${option}${index}`}
                  label={
                    option === AUCTIONS_TYPES_FILTERS.onlineOnly.value
                      ? AUCTIONS_TYPES_FILTERS.onlineOnly.display
                      : option
                  }
                  selected={isSelected(value, 'auctionType', option)}
                  onClick={() => {
                    handleClick(value, 'auctionType', option, setValue, true);
                  }}
                />
              ))}
              <h5>Reserve</h5>
              {!!hasReservePrice.length ? (
                <LabelCard
                  label={'Without Reserve'}
                  selected={value['hasReservePrice'] !== undefined}
                  onClick={() => {
                    setValue({
                      ...value,
                      hasReservePrice:
                        value['hasReservePrice'] === undefined
                          ? false
                          : undefined,
                    });
                  }}
                />
              ) : (
                ''
              )}
              <h5>Online Bidding</h5>
              {!!onlineBiddingAvailable.length ? (
                <LabelCard
                  label={'Online Bid Eligible'}
                  selected={value.onlineBiddingAvailable !== undefined}
                  onClick={() => {
                    setValue({
                      ...value,
                      onlineBiddingAvailable:
                        value.onlineBiddingAvailable === undefined
                          ? true
                          : undefined,
                    });
                  }}
                />
              ) : (
                ''
              )}
            </div>
            {renderAuctionsFilter(value?.auctionType?.[0])}
          </div>
          <div className={styles.innerCardYears}>
            <h5>Auction Year</h5>
            <div className={styles.year}>
              {auctionYearList.map((option, index) => (
                <LabelCard
                  key={`${option}${index}`}
                  label={option}
                  containerStyle={styles.yearLabelCard}
                  selected={isSelected(value, 'auctionYear', option)}
                  disabled={isDisabled}
                  onClick={() =>
                    handleClick(value, 'auctionYear', option, setValue)
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer
        clearOnClick={() => cancel(MORE_INITIAL_STATE)}
        updateOnClick={() => update(value)}
        updateDisable={!enabled(value)}
      />
    </div>
  );
};

export default More;
