import React, { useState } from 'react';
import Popover from 'components/Popover';
import Year from './year';
import AuctionType from './auctionType';
import styles from './header.module.scss';

const Header = ({ years, selectedFilters, setSelectedFilters }) => {
  const [yearPopoverOpen, setYearPopoverOpen] = useState(false);
  const [auctionTypePopoverOpen, setAuctionTypePopoverOpen] = useState(false);
  const yearToggle = () => setYearPopoverOpen(!yearPopoverOpen);
  const auctionTypeToggle = () =>
    setAuctionTypePopoverOpen(!auctionTypePopoverOpen);

  return (
    <div className={styles.header}>
      <div className={styles.innerSection}>
        <h3>AUCTION RESULTS</h3>
        <div className={styles.mainList}>
          <div className={styles.listWrapper}>
            <Popover
              label="Year"
              id="year"
              popoverOpen={yearPopoverOpen}
              toggle={yearToggle}
              active={!!selectedFilters.years.length}
            >
              <Year
                years={years}
                state={selectedFilters.years}
                update={values => {
                  setSelectedFilters({ ...selectedFilters, years: values });
                  yearToggle();
                }}
              />
            </Popover>

            <Popover
              label="Auction Type"
              id="auctionType"
              active={!!selectedFilters.auctionType.length}
              popoverOpen={auctionTypePopoverOpen}
              toggle={auctionTypeToggle}
            >
              <AuctionType
                state={selectedFilters.auctionType}
                update={value => {
                  setSelectedFilters({
                    ...selectedFilters,
                    auctionType: value,
                  });
                  auctionTypeToggle();
                }}
              />
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
