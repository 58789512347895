export const SHIPPING_OPTIONS = [
  {
    label: 'Fedex Domestic Express ($16.50 in US, 2-3 day delivery)',
    price: 16.5,
    id: 1,
  },
  {
    label: 'Fedex Domestic Overnight ($36.50 in US, overnight delivery)',
    price: 36.5,
    id: 2,
  },
  {
    label: 'Fedex Canada or Mexico ($49.20, 3-5 day delivery)',
    price: 49.2,
    id: 3,
  },
  {
    label: 'Fedex International ($69.80, 3-5 day delivery)',
    price: 69.8,
    id: 4,
  },
];

export const YEAR_SHIPPING_OPTIONS = [
  {
    label: 'Fedex Domestic Express ($49.50 in US, 2-3 day delivery)',
    price: 49.5,
    id: 1,
  },
  {
    label: 'Fedex Domestic Overnight ($109.5 in US, overnight delivery)',
    price: 109.5,
    id: 2,
  },
  {
    label: 'Fedex Canada or Mexico ($147.6, 3-5 day delivery)',
    price: 147.6,
    id: 3,
  },
  {
    label: 'Fedex International ($209.4, 3-5 day delivery)',
    price: 209.4,
    id: 4,
  },
];

export const QUANTITY_OPTIONS = Array.from({ length: 10 }, (_, i) => {
  const n = i + 1;
  return { label: n + '', id: n };
});

export const NO_AVAILABLE_PRICE_MSG = 'No price available yet';
