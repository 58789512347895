import React from 'react';
import Vehicles from './vehicles';
import styles from './TabsVehiclesList.module.scss';

const TabsVehiclesList = ({ tabs, type, defaultTap }) => {
  return (
    <div className={styles.listContainer}>
      <Vehicles tabs={tabs} type={type} defaultTap={defaultTap} />
    </div>
  );
};

export default TabsVehiclesList;
