import React from 'react';
import SECTIONS from 'src/utils/sectionConstants';
import FeaturedVehicles from 'features/featuredVehicles';
import UpcomingAuctions from 'features/UpcomingAuctions/UpcomingAuctions';
import AuctionResults from 'features/AuctionResults/AuctionResults';
import PrivateSales from 'features/PrivateSales';
import ActiveJobs from 'features/ActiveJobs';
import AvailableLots from 'features/AvailableLots';
import OrderCatalog from 'features/orderCatalog/index.jsx';

const DynamicSection = ({ section }) => {
  const renderSections = () => {
    switch (section?.sections) {
      case SECTIONS.DynamicSections.featuredVehicles:
        return <FeaturedVehicles section={section} />;
      case SECTIONS.DynamicSections.horizontalUpcomingAuctions:
        return <UpcomingAuctions fullWidth={false} />;
      case SECTIONS.DynamicSections.horizontalUpcomingOnlineAuctions:
        return <UpcomingAuctions fullWidth={false} auctionType={'online'} />;
      case SECTIONS.DynamicSections.verticalUpcomingAuctions:
        return <UpcomingAuctions fullWidth={true} />;
      case SECTIONS.DynamicSections.auctionResults:
        return <AuctionResults />;
      case SECTIONS.DynamicSections.privateSales:
        return <PrivateSales />;
      case SECTIONS.DynamicSections.activeJobs:
        return <ActiveJobs />;
      case SECTIONS.DynamicSections.availableLots:
        return <AvailableLots />;
      case SECTIONS.DynamicSections.orderACatalogue:
        return <OrderCatalog />;
      default:
        return '';
    }
  };

  return <>{renderSections()}</>;
};
export default DynamicSection;
