import React from 'react';
import STYLES from 'src/utils/stylesConstants';
import ParagraphWithHeader from 'components/ParagraphWithHeader';
import TitleDescriptionCollapse from 'components/TitleDescriptionCollapse';

const paragraphWithHeadline = ({
  styleType,
  description,
  headline,
  ismarkdown,
  callToAction,
}) => {
  switch (styleType) {
    case STYLES.readMoreLess:
      return (
        <TitleDescriptionCollapse
          headline={headline}
          isMarkdown={ismarkdown}
          description={description}
          collapse={true}
          callToActions={callToAction}
        />
      );
    case STYLES.twoColumns.top:
    case STYLES.twoColumns.bottom:
      return (
        <TitleDescriptionCollapse
          headline={headline}
          isMarkdown={ismarkdown}
          description={description}
          collapse={false}
          callToActions={callToAction}
          CTAbottom={styleType === STYLES.twoColumns.bottom ? true : false}
          CTAtop={styleType === STYLES.twoColumns.top ? true : false}
        />
      );
    default:
      return (
        <ParagraphWithHeader
          title={headline}
          isMarkdown={ismarkdown}
          description={description}
          callToActions={callToAction}
        />
      );
  }
};

export default paragraphWithHeadline;
