import React from 'react';
import _ from 'lodash';
import Button from 'components/Button';
import styles from './filters.module.scss';

const Filters = ({ tabs, vehicleType, setVehicleType }) => {
  return (
    <div className={styles.filterContainer}>
      {tabs?.length > 0 &&
        tabs.map(({ tabName }, index) => (
          <Button
            key={index}
            type={
              vehicleType === tabName
                ? 'app-primary-button'
                : 'app-default-button'
            }
            handleClick={() =>
              _.isFunction(setVehicleType) && setVehicleType(tabName)
            }
          >
            {tabName}
          </Button>
        ))}
    </div>
  );
};

export default Filters;
