import React, { useState } from 'react';
import _ from 'lodash';
import { Formik, Form } from 'formik';
import Search from 'components/Search';
import LabelCard from 'components/LabelCard';
import Footer from 'components/FilterFooter';
import styles from './make.module.scss';

const renderCars = (search, selectedCars, setCars, makeList) => {
  const searchCars = _.filter(makeList, car =>
    _.startsWith(car.toLowerCase(), search.toLowerCase())
  );

  return searchCars.map((car, index) => (
    <LabelCard
      key={`${car}${index}`}
      label={car}
      selected={!!_.filter(selectedCars, o => o === car).length}
      onClick={() => setCars(_.xor(selectedCars, [car]))}
    />
  ));
};

const Make = ({ make, makeList, update, cancel }) => {
  const [selectedCars, setCars] = useState(make);
  return (
    <Formik initialValues={{ search: '' }}>
      {({ values }) => (
        <Form>
          <Search
            name="search"
            placeholder="Search Vehicles"
            containerStyle={styles.makeSearch}
          />
          <div className={styles.labelCardSection}>
            {renderCars(values.search, selectedCars, setCars, makeList)}
          </div>
          <Footer
            updateDisable={!!!selectedCars.length}
            clearOnClick={() => cancel()}
            updateOnClick={() => update(selectedCars)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default Make;
