import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import TextInput from 'components/form-elements/TextInput';
import Button from 'components/Button';
import './search.scss';

const Search = ({ placeholder, name, containerStyle, transparentHeader }) => (
  <div
    className={`search text-subdued ${containerStyle} ${
      transparentHeader ? 'transparent' : ''
    }`}
  >
    <TextInput name={name} placeholder={placeholder} floating={false} />
    <Button type="search-icon" handleClick={() => {}}>
      <FontAwesomeIcon icon={faSearch} />
    </Button>
  </div>
);

export default Search;
