const MORE_INITIAL_STATE = {
  auctionYear: [],
  liveAuctionVenue: [],
  itemType: [],
  hasReservePrice: undefined,
  onlineBiddingAvailable: undefined,
  auctionType: [],
  onlineAuctionName: [],
};

export { MORE_INITIAL_STATE };
