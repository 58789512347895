import React, { useRef } from 'react';
import SEO from 'components/seo';
import Button from 'components/Button';
import Card from './card';
import styles from './expandableList.module.scss';

const scrollToRef = (ref, headerRef) => {
  const top = window.innerWidth <= 767 ? 200 : 250;
  window.scrollTo(
    0,
    ref.current.offsetTop - top - headerRef.current.clientHeight
  );
};

const ExpandableList = ({ section }) => {
  const { collections, title } = section;
  let refs = {};
  const headerRef = useRef(null);

  const renderFaqlinks = (collections, headerRef, refs) =>
    collections?.length &&
    collections.map(({ id, title }) => (
      <li key={`faqHeader${id}`}>
        <Button handleClick={() => scrollToRef(refs[`${id}_ref`], headerRef)}>
          {title}
        </Button>
      </li>
    ));

  const renderFaqContent = collections =>
    collections?.length &&
    collections.map(({ id, title, paragraphs }) => {
      refs[`${id}_ref`] = React.createRef();
      return (
        <div
          className={styles.cardSectionWrapper}
          key={`questionsWrapper${id}`}
          ref={refs[`${id}_ref`]}
        >
          <h6 className={styles.title}>{title}</h6>
          {paragraphs?.length &&
            paragraphs.map(
              ({ headline, paragraph: { childMarkdownRemark }, id }) => (
                <Card
                  key={`question${id}`}
                  title={headline}
                  description={childMarkdownRemark.html}
                />
              )
            )}
        </div>
      );
    });

  return (
    <>
      <SEO title="Faq" />
      <div className={styles.faq}>
        <div className={styles.pageHeading}>
          <div className={styles.innerSection}>
            <h2>{title}</h2>
            <div className={styles.tabs}>
              <ul ref={headerRef}>
                {renderFaqlinks(collections, headerRef, refs)}
              </ul>
            </div>
          </div>
        </div>

        <div className={styles.cardSection}>
          {renderFaqContent(collections)}
        </div>
      </div>
    </>
  );
};

export default ExpandableList;
